import React from 'react';
import { NavLink } from 'react-router-dom';
import bg from '../../assets/images/bg-img/8.jpg';
import SpeakerList from '../SpeakerList';

export default function Speakers(props) {
  return (
    <section className="our-speaker-area bg-img bg-gradient-overlay section-padding-100-60" style={{
      backgroundImage: `url(${bg})`
    }}>
      <div className="container">
        <div className="row">

          <div className="col-12">
            <div className="section-heading text-center wow fadeInUp" data-wow-delay="300ms">
              <p>Our Speakers</p>
              <h4>Who’s speaking</h4>
            </div>
          </div>
        </div>

        <div className="row">

          <SpeakerList speakers={props.speakers} />

          <div className="col-12">
            <div className="more-speaker-btn text-center mt-20 mb-40 wow fadeInUp" data-wow-delay="300ms">
              <NavLink className="btn confer-btn-white" to="/speakers">view all Speaker <i className="zmdi zmdi-long-arrow-right"></i></NavLink>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}