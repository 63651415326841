import React from 'react';
import {
  Route,
  BrowserRouter as Router,
} from "react-router-dom";
import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './views/HomePage';
import SpeakersPage from './views/SpeakersPage';
import GalleryPage from './views/GalleryPage';
import ContactPage from './views/ContactPage';
import ScrollToTop from './components/ScrollToTop';
import LoginPage from './views/LoginPage';
import FeedsPage from './views/FeedsPage';

function App() {

  return (
    <Router>
      <ScrollToTop />
      <Header />
      <Route exact path="/" component={HomePage}></Route>
      <Route path="/speakers" component={SpeakersPage}></Route>
      <Route path="/gallery" component={GalleryPage}></Route>
      <Route path="/contact" component={ContactPage}></Route>
      <Route path="/login" component={LoginPage}></Route>
      <Route path="/feeds" component={FeedsPage}></Route>
      <Footer />
    </Router>
  )
}

export default App;
