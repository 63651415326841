import React, { Fragment } from 'react';
import SpeakerCard from './SpeakerCard';

export default function SpeakerList(props) {
  const speakers = props.speakers;
  const listSpeakers = speakers.map((speaker) =>
    <div className="col-12 col-md-6 col-lg-4" key={speaker.id}>
      <SpeakerCard url={speaker.url} name={speaker.name} position={speaker.position} />
    </div>
  );
  return <Fragment>{listSpeakers}</Fragment>
}