import React from 'react';
import Breadcrumb from '../components/Breadcrumb.js';
import img1 from '../assets/images/core-img/icon-5.png';
import img2 from '../assets/images/core-img/icon-6.png';
import img3 from '../assets/images/core-img/icon-7.png';
import img4 from '../assets/images/core-img/icon-8.png';

export default function ContactPage(props) {
  return (
    <div>
      <Breadcrumb name="Contact" />

      <section className="contact--us-area section-padding-100-0">
        <div className="container">
          <div className="row">

            <div className="col-12 col-lg-6">
              <div className="contact-us-thumb mb-100">
                <img src="https://images.unsplash.com/uploads/1413222992504f1b734a6/1928e537?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=750&q=80" alt=""></img>
              </div>
            </div>


            <div className="col-12 col-lg-6">
              <div className="contact_from_area mb-100 clearfix">

                <div className="contact-heading">
                  <h4>Contact us</h4>
                  <p>Have an issue or enquiry? Kindly fill and submit the form below.</p>
                </div>
                <div className="contact_form">
                  <form action="mail.php" method="post">
                    <div className="contact_input_area">
                      <div className="row">

                        <div className="col-12 col-lg-6">
                          <div className="form-group">
                            <input type="text" className="form-control mb-30" name="name" id="name" placeholder="Your Name" required></input>
                          </div>
                        </div>

                        <div className="col-12 col-lg-6">
                          <div className="form-group">
                            <input type="text" className="form-control mb-30" name="name" id="name-2" placeholder="Last Name" required></input>
                          </div>
                        </div>

                        <div className="col-12 col-lg-6">
                          <div className="form-group">
                            <input type="email" className="form-control mb-30" name="email" id="email" placeholder="E-mail" required></input>
                          </div>
                        </div>

                        <div className="col-12 col-lg-6">
                          <div className="form-group">
                            <input type="text" className="form-control mb-30" name="subject" id="subject" placeholder="Your Number"></input>
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="form-group">
                            <textarea name="message" className="form-control mb-30" id="message" cols="30" rows="6" placeholder="Message" required></textarea>
                          </div>
                        </div>

                        <div className="col-12">
                          <button type="submit" className="btn confer-btn">Send Message <i className="zmdi zmdi-long-arrow-right"></i></button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* contact information */}
      <div className="container section-padding-80">
        <div className="row">
          <div className="col-12">
            <div className="contact--info-area bg-boxshadow">
              <div className="row">

                <div className="col-12 col-md-6 col-lg-3">
                  <div className="single-contact--info text-center">

                    <div className="contact--info-icon">
                      <img src={img1} alt=""></img>
                    </div>
                    <h5>184 Ume Street</h5>
                  </div>
                </div>


                <div className="col-12 col-md-6 col-lg-3">
                  <div className="single-contact--info text-center">

                    <div className="contact--info-icon">
                      <img src={img2} alt=""></img>
                    </div>
                    <h5>(234) 446 9371</h5>
                  </div>
                </div>


                <div className="col-12 col-md-6 col-lg-3">
                  <div className="single-contact--info text-center">

                    <div className="contact--info-icon">
                      <img src={img3} alt=""></img>
                    </div>
                    <h5>app@confer.com</h5>
                  </div>
                </div>


                <div className="col-12 col-md-6 col-lg-3">
                  <div className="single-contact--info text-center">

                    <div className="contact--info-icon">
                      <img src={img4} alt=""></img>
                    </div>
                    <h5>confer.web.app</h5>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}