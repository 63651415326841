import React from 'react';

export default function Contact(props) {
  return (
    <section className="contact-our-area section-padding-100-0">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="section-heading-2 text-center wow fadeInUp" data-wow-delay="300ms">
              <p>Have Question?</p>
              <h4>Contact us</h4>
            </div>
          </div>
        </div>

        <div className="row justify-content-between">
          <div className="col-12 col-sm-3">
            <div className="contact-information mb-100">

              <div className="single-contact-info wow fadeInUp" data-wow-delay="300ms">
                <p>Address:</p>
                <h6>184 Ume Street</h6>
              </div>

              <div className="single-contact-info wow fadeInUp" data-wow-delay="300ms">
                <p>Phone:</p>
                <h6>(234) 446 9371</h6>
              </div>

              <div className="single-contact-info wow fadeInUp" data-wow-delay="300ms">
                <p>Email:</p>
                <h6>app@confer.com</h6>
              </div>

              <div className="single-contact-info wow fadeInUp" data-wow-delay="300ms">
                <p>Website:</p>
                <h6>confer.web.app</h6>
              </div>
            </div>
          </div>

          <div className="col-12 col-sm-8">

            <div className="contact_from_area mb-100 clearfix wow fadeInUp" data-wow-delay="300ms">
              <div className="contact_form">
                <form action="mail.php" method="post" id="main_contact_form">
                  <div className="contact_input_area">
                    <div id="success_fail_info"></div>
                    <div className="row">

                      <div className="col-12 col-lg-6">
                        <div className="form-group">
                          <input type="text" className="form-control mb-30" name="name" id="name" placeholder="Your Name"></input>
                        </div>
                      </div>

                      <div className="col-12 col-lg-6">
                        <div className="form-group">
                          <input type="text" className="form-control mb-30" name="name" id="name2" placeholder="Last Name"></input>
                        </div>
                      </div>

                      <div className="col-12 col-lg-6">
                        <div className="form-group">
                          <input type="email" className="form-control mb-30" name="email" id="email" placeholder="E-mail"></input>
                        </div>
                      </div>

                      <div className="col-12 col-lg-6">
                        <div className="form-group">
                          <input type="text" className="form-control mb-30" name="subject" id="subject" placeholder="Your Number"></input>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group">
                          <textarea name="message" className="form-control mb-30" id="message" cols="30" rows="6" placeholder="Your Message *"></textarea>
                        </div>
                      </div>

                      <div className="col-12">
                        <button type="submit" className="btn confer-btn">Send Message <i className="zmdi zmdi-long-arrow-right"></i></button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}