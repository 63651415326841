import React from 'react';
import { NavLink } from 'react-router-dom';

export default function SpeakerCard(props) {
  return (
    <div className="single-speaker-area bg-gradient-overlay-2 wow fadeInUp" data-wow-delay="300ms">

      <div className="speaker-single-thumb">
        <img src={props.url} alt=""></img>
      </div>

      <div className="social-info">
        <NavLink to="#"><i className="zmdi zmdi-facebook"></i></NavLink>
        <NavLink to="#"><i className="zmdi zmdi-instagram"></i></NavLink>
        <NavLink to="#"><i className="zmdi zmdi-twitter"></i></NavLink>
        <NavLink to="#"><i className="zmdi zmdi-linkedin"></i></NavLink>
      </div>

      <div className="speaker-info">
        <h5>{props.name}</h5>
        <p>{props.position}</p>
      </div>
    </div>
  )
}