import React from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../assets/images/core-img/logo.png';

export default function Footer(props) {
  return (
    <footer className="footer-area bg-img bg-overlay-2 section-padding-100-0">
      <div className="main-footer-area">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-6 col-lg-3">
              <div className="single-footer-widget mb-60 wow fadeInUp" data-wow-delay="300ms">

                <NavLink to="#" className="footer-logo"><img src={logo} alt=""></img></NavLink>
                <p>To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain.</p>


                <div className="social-info">
                  <NavLink to="#"><i className="zmdi zmdi-facebook"></i></NavLink>
                  <NavLink to="#"><i className="zmdi zmdi-instagram"></i></NavLink>
                  <NavLink to="#"><i className="zmdi zmdi-twitter"></i></NavLink>
                  <NavLink to="#"><i className="zmdi zmdi-linkedin"></i></NavLink>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-lg-3">
              <div className="single-footer-widget mb-60 wow fadeInUp" data-wow-delay="300ms">

                <h5 className="widget-title">Contact</h5>


                <div className="footer-contact-info">
                  <p><i className="zmdi zmdi-map"></i> 184 Ume Street</p>
                  <p><i className="zmdi zmdi-phone"></i> (234) 446 9371</p>
                  <p><i className="zmdi zmdi-email"></i> app@confer.com</p>
                  <p><i className="zmdi zmdi-globe"></i> confer.web.app</p>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-lg-3">
              <div className="single-footer-widget mb-60 wow fadeInUp" data-wow-delay="300ms">

                <h5 className="widget-title">Workshops</h5>


                <ul className="footer-nav">
                  <li><NavLink to="#">OSCA Africa</NavLink></li>
                  <li><NavLink to="#">Microsoft Excel Basics</NavLink></li>
                  <li><NavLink to="#">Forum Speaker Series</NavLink></li>
                  <li><NavLink to="#">Tedx Uniport Conference</NavLink></li>
                </ul>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-lg-3">
              <div className="single-footer-widget mb-60 wow fadeInUp" data-wow-delay="300ms">

                <h5 className="widget-title">Gallery</h5>


                <div className="footer-gallery">
                  <div className="row">
                    <div className="col-4">
                      <span className="single-gallery-item"><img src="https://firebasestorage.googleapis.com/v0/b/confer-2020.appspot.com/o/gallery%2Fimage-1573566111234?alt=media&token=630a379f-d9bd-4687-8005-178fe7663204" alt=""></img></span>
                    </div>
                    <div className="col-4">
                      <span className="single-gallery-item"><img src="https://firebasestorage.googleapis.com/v0/b/confer-2020.appspot.com/o/gallery%2Fimage-1573878933660?alt=media&token=d7f651da-0208-40cf-b194-3039e0b19c39" alt=""></img></span>
                    </div>
                    <div className="col-4">
                      <span className="single-gallery-item"><img src="https://firebasestorage.googleapis.com/v0/b/confer-2020.appspot.com/o/gallery%2Fimage-1573879108572?alt=media&token=d6b9e3c1-a339-47e6-b8c3-1e16762fc136" alt=""></img></span>
                    </div>
                    <div className="col-4">
                      <span className="single-gallery-item"><img src="https://firebasestorage.googleapis.com/v0/b/confer-2020.appspot.com/o/gallery%2Fimage-1573879071490?alt=media&token=0cff51e6-0037-4607-be09-39a9c9d71ce8" alt=""></img></span>
                    </div>
                    <div className="col-4">
                      <span className="single-gallery-item"><img src="https://firebasestorage.googleapis.com/v0/b/confer-2020.appspot.com/o/gallery%2F1.jpeg?alt=media&token=35a96206-22d9-4d58-8439-592ad48e0849" alt=""></img></span>
                    </div>
                    <div className="col-4">
                      <span className="single-gallery-item"><img src="https://firebasestorage.googleapis.com/v0/b/confer-2020.appspot.com/o/gallery%2Fimage-1573659197610?alt=media&token=69d644c1-0737-4ae7-b1d5-2e78ef74c494" alt=""></img></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="container">
        <div className="copywrite-content">
          <div className="row">

            <div className="col-12 col-md-6">
              <div className="copywrite-text">
                <p>
                  Copyright &copy;{new Date().getFullYear()} All rights reserved | Made with <i className="fa fa-heart-o" aria-hidden="true"></i> by <NavLink to="http://www.slightlynerd.com" target="_blank">Kelvin Gobo</NavLink>
                </p>
              </div>
            </div>

            <div className="col-12 col-md-6">
              <div className="footer-menu">
                <ul className="nav">
                  <li><NavLink to="/"><i className="zmdi zmdi-circle"></i> Terms of Service</NavLink></li>
                  <li><NavLink to="/"><i className="zmdi zmdi-circle"></i> Privacy Policy</NavLink></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}