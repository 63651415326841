import React from 'react';
import FeedCard from './FeedCard';

export default function FeedList(props) {
  const feeds = props.feeds;
  const feedList = feeds.map((feed, index) =>
    <div className="col-12 col-md-6 col-lg-4" key={index}>
      <FeedCard text={feed.text} author={feed.author} />
    </div>
  )
  return <React.Fragment>{feedList}</React.Fragment>
}