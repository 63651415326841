import React from 'react';
import { NavLink } from 'react-router-dom';
import img1 from '../../assets/images/core-img/p-1.png';
import img2 from '../../assets/images/core-img/p-2.png';
import img3 from '../../assets/images/core-img/p-3.png';
import img4 from '../../assets/images/core-img/p-4.png';
import img5 from '../../assets/images/core-img/p-5.png';
import img6 from '../../assets/images/core-img/p-6.png';
import img7 from '../../assets/images/core-img/p-7.png';
import img8 from '../../assets/images/core-img/p-8.png';

export default function Partners(props) {
  return (
    <section className="our-sponsor-client-area section-padding-100">
      <div className="container">
        <div className="row">

          <div className="col-12">
            <div className="section-heading-2 text-center wow fadeInUp" data-wow-delay="300ms">
              <p>Partners &amp; Sponsors</p>
              <h4>OFFICIAL SPONSOR</h4>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">

            <div className="our-sponsor-area d-flex flex-wrap">

              <div className="single-sponsor wow fadeInUp" data-wow-delay="300ms">
                <NavLink to="#"><img src={img1} alt=""></img></NavLink>
              </div>

              <div className="single-sponsor wow fadeInUp" data-wow-delay="300ms">
                <NavLink to="#"><img src={img2} alt=""></img></NavLink>
              </div>

              <div className="single-sponsor wow fadeInUp" data-wow-delay="300ms">
                <NavLink to="#"><img src={img3} alt=""></img></NavLink>
              </div>

              <div className="single-sponsor wow fadeInUp" data-wow-delay="300ms">
                <NavLink to="#"><img src={img4} alt=""></img></NavLink>
              </div>

              <div className="single-sponsor wow fadeInUp" data-wow-delay="300ms">
                <NavLink to="#"><img src={img5} alt=""></img></NavLink>
              </div>

              <div className="single-sponsor wow fadeInUp" data-wow-delay="300ms">
                <NavLink to="#"><img src={img6} alt=""></img></NavLink>
              </div>

              <div className="single-sponsor wow fadeInUp" data-wow-delay="300ms">
                <NavLink to="#"><img src={img7} alt=""></img></NavLink>
              </div>

              <div className="single-sponsor wow fadeInUp" data-wow-delay="300ms">
                <NavLink to="#"><img src={img8} alt=""></img></NavLink>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>
  )
}