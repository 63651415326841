// Your web app's Firebase configuration
export const firebaseConfig = {
  apiKey: "AIzaSyDuvSA2ATZKV5KwArp-HzMBBs51mnU-BF8",
  authDomain: "confer-2020.firebaseapp.com",
  databaseURL: "https://confer-2020.firebaseio.com",
  projectId: "confer-2020",
  storageBucket: "confer-2020.appspot.com",
  messagingSenderId: "953787389464",
  appId: "1:953787389464:web:7fd286d2cb7a1c1374d56d",
  measurementId: "G-XELWV3NSM4"
};
