import React, { Component } from 'react';
import firebase from 'firebase';
import { NavLink } from 'react-router-dom';
import logo from '../assets/images/core-img/logo.png';
import $ from 'jquery';

function User(props) {
  const user = props.user;
  if (user) {
    return <div className="btn text-white">Hello, <br /><span style={{ color: '#df42b1' }}>{user.displayName}</span></div>
  }
  return <NavLink to="/login" className="btn confer-btn mt-3 mt-lg-0 ml-3 ml-lg-5" >Sign In <i className="zmdi zmdi-long-arrow-right"></i></NavLink>
}

export default class Header extends Component {

  constructor(props) {
    super(props);
    this.state = {
      user: null,
    }
  }

  componentDidMount() {
    // set observer for user
    firebase.auth().onAuthStateChanged(currentUser => {
      if (currentUser) {
        this.setState({ user: currentUser });
      }
    });
    const links = document.querySelectorAll('a');
    links.forEach(link =>
      link.addEventListener('click', e => {
        this.closeNav()
      })
    );

    // Variables
    const navContainer = $('.classy-nav-container');
    const classy_nav = $('.classynav ul');
    const classy_navli = $('.classynav > ul > li');
    const navbarToggler = $('.classy-navbar-toggler');
    const closeIcon = $('.classycloseIcon');
    const navToggler = $('.navbarToggler');
    const classyMenu = $('.classy-menu');
    const var_window = $(window);

    // default options
    const defaultOpt = $.extend({
      breakpoint: 991,
      openCloseSpeed: 500,
      megaopenCloseSpeed: 800
    });

    // navbar toggler
    navbarToggler.on('click', function () {
      navToggler.toggleClass('active');
      classyMenu.toggleClass('menu-on');
    });

    // close icon
    closeIcon.on('click', function () {
      classyMenu.removeClass('menu-on');
      navToggler.removeClass('active');
    });

    // add dropdown & megamenu class in parent li class
    classy_navli.has('.dropdown').addClass('cn-dropdown-item');
    classy_navli.has('.megamenu').addClass('megamenu-item');

    // adds toggle button to li items that have children
    classy_nav.find('li a').each(function () {
      if ($(this).next().length > 0) {
        $(this).parent('li').addClass('has-down').append('<span className="dd-trigger"></span>');
      }
    });

    // expands the dropdown menu on each click
    classy_nav.find('li .dd-trigger').on('click', function (e) {
      e.preventDefault();
      $(this).parent('li').children('ul').stop(true, true).slideToggle(defaultOpt.openCloseSpeed);
      $(this).parent('li').toggleClass('active');
    });

    // add padding in dropdown & megamenu item
    $('.megamenu-item').removeClass('has-down');

    // expands the megamenu on each click
    classy_nav.find('li .dd-trigger').on('click', function (e) {
      e.preventDefault();
      $(this).parent('li').children('.megamenu').slideToggle(defaultOpt.megaopenCloseSpeed);
    });

    // check browser width in real-time
    function breakpointCheck() {
      const windoWidth = window.innerWidth;
      if (windoWidth <= defaultOpt.breakpoint) {
        navContainer.removeClass('breakpoint-off').addClass('breakpoint-on');
      } else {
        navContainer.removeClass('breakpoint-on').addClass('breakpoint-off');
      }
    }

    breakpointCheck();

    var_window.on('resize', function () {
      breakpointCheck();
    });

    // sidebar menu enable
    if (defaultOpt.sideMenu === true) {
      navContainer.addClass('sidebar-menu-on').removeClass('breakpoint-off');
    }
  }

  closeNav() {
    const sidebar = $('.classy-menu');
    const toggler = $('.navbarToggler');
    if (sidebar.hasClass('menu-on')) {
      sidebar.removeClass('menu-on');
      toggler.removeClass('active');
    }
  }

  render() {
    return (
      <header className="header-area">
        <div className="classy-nav-container breakpoint-off">
          <div className="container">

            <nav className="classy-navbar justify-content-between" id="conferNav">


              <NavLink className="nav-brand" to="/"><img src={logo} alt=""></img></NavLink>


              <div className="classy-navbar-toggler">
                <span className="navbarToggler"><span></span><span></span><span></span></span>
              </div>


              <div className="classy-menu">

                <div className="classycloseIcon">
                  <div className="cross-wrap"><span className="top"></span><span className="bottom"></span></div>
                </div>

                <div className="classynav">
                  <ul id="nav">
                    <li className="active"><NavLink to="/">Home</NavLink></li>
                    <li><NavLink to="/speakers">Speakers</NavLink></li>
                    <li><NavLink to="/gallery">Gallery</NavLink></li>
                    <li><NavLink to="/feeds">Live Feeds</NavLink></li>
                    <li><NavLink to="/contact">Contact</NavLink></li>
                  </ul>


                  <User user={this.state.user} />
                  {/* <button id="pushBtn" className="btn confer-btn mt-3 mt-lg-0 ml-3 ml-lg-5" onClick={this.handleNotificationSub}>Subscribe to Notifications <i className="zmdi zmdi-long-arrow-right"></i></button> */}
                </div>

              </div>
            </nav>
          </div>
        </div>
      </header>
    )
  }
}