import React from 'react';
import { NavLink } from 'react-router-dom';
import bg1 from '../../assets/images/bg-img/7.jpg';

export default function Welcome(props) {
  return (
    <section className="welcome-area">
      <div className="welcome-slides owl-carousel">

        <div className="single-welcome-slide bg-img bg-overlay jarallax" style={{ backgroundImage: `url(${bg1})` }}>
          <div className="container h-100">
            <div className="row h-100 align-items-center">

              <div className="col-12">
                <div className="welcome-text text-right">
                  <h2 data-animation="fadeInUp" data-delay="300ms">Confer <br></br>2020</h2>
                  <h6 data-animation="fadeInUp" data-delay="500ms">Lagos, Abuja, Port Harcourt, Uyo</h6>
                  <div className="hero-btn-group" data-animation="fadeInUp" data-delay="700ms">
                    <NavLink to="#" className="btn confer-btn">More Information <i className="zmdi zmdi-long-arrow-right"></i></NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className="bg-img bg-dark event-details jarallax section-padding-80 text-white">
          <div className="container h-100">
            <div className="row h-100 align-items-center">

              <div className="col-12">
                <div className="welcome-text-two text-center">
                  <h5 className="text-white" data-animation="fadeInUp" data-delay="100ms">DevConf</h5>
                  <h2 className="text-white" data-animation="fadeInUp" data-delay="300ms">The Autograph</h2>

                  <div className="event-meta" data-animation="fadeInUp" data-delay="500ms">
                    <span className="event-date mr-3"><i className="zmdi zmdi-account"></i> January 14, 2020</span>
                    <span className="event-author"><i className="zmdi zmdi-alarm-check"></i> 10:00 AM</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </section>
  )
}
