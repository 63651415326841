import React from 'react';
import Breadcrumb from '../components/Breadcrumb.js';
import SpeakerList from '../components/SpeakerList.js';
import { speakers } from '../data/speakers';

export default function SpeakerPage(props) {
  return (
    <div>
      <Breadcrumb name="Speakers" />
      <section className="our-speaker-area section-padding-100-60">
        <div className="container">
          <div className="row">

            <div className="col-12">
              <div className="section-heading text-center wow fadeInUp" data-wow-delay="300ms">
                <p className="text-secondary">Our Speakers</p>
                <h4 className="text-dark">Who’s speaking</h4>
              </div>
            </div>
          </div>

          <div className="row">

            <SpeakerList speakers={speakers} />

          </div>
        </div>
      </section>
    </div>
  )
}