import React from 'react';
import firebase from 'firebase';
import * as firebaseui from 'firebaseui';

export default class LoginPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      ui: new firebaseui.auth.AuthUI(firebase.auth()),
    };
  }

  componentDidMount() {
    this.state.ui.start('#firebaseui-auth-container', {
      signInOptions: [
        {
          provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
          requireDisplayName: true
        },
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      ],
      signInSuccessUrl: '/'
    })
  }

  componentWillUnmount() {
    this.state.ui.delete();
  }

  render() {
    return (
      <div>
        <section className="our-speaker-area section-padding-100-60">
          <div className="container">
            <div className="row">
              <div className="col-md-12 mt-5 text-center">
                <h2>Login to <span style={{ color: '#df42b1' }}>Confer</span></h2>
                <p>Login to access more features like post feeds, upload images and more!</p>
                <div id="firebaseui-auth-container"></div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }

}