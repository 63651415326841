import React from 'react';
import firebase from 'firebase';
import Breadcrumb from '../components/Breadcrumb';
import Spinner from '../components/Spinner';
import FeedList from '../components/feeds/FeedList';
import FeedForm from '../components/feeds/FeedForm';

export default class Feeds extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      feeds: [],
      loading: false,
      db: firebase.database(),
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.state.db.ref('/feeds').orderByChild('timestamp').on('value', (snapshot) => {
      const tempFeed = [];
      snapshot.forEach(childSnapshot => {
        const obj = childSnapshot.val();
        tempFeed.push(obj);
      });
      this.setState({
        feeds: tempFeed.reverse(),
        loading: false,
      });
    })
  }

  render() {
    return (
      <div>
        <Breadcrumb name="Live Feeds" />
        <section className="our-speaker-area section-padding-100-60 bg-light">
          <div className="container">
            <div className="row">

              <div className="col-12">
                <div className="section-heading text-center wow fadeInUp" data-wow-delay="300ms">
                  <p className="text-secondary">WHAT'S HAPPENING</p>
                  <h4 className="text-dark">LATEST FEEDS</h4>
                </div>
              </div>

              <div className="col-12 mb-5">
                <FeedForm />
              </div>

              <div className="col-12 text-center">
                <Spinner loading={this.state.loading} />
              </div>
              <FeedList feeds={this.state.feeds} />

            </div>

          </div>
        </section>
      </div>
    )
  }
}