export const speakers = [
  {
    id: 0,
    name: 'Gino Osahon',
    position: 'Hub Manager, Ken Saro-Wiwa Innovation Hub',
    url: 'https://firebasestorage.googleapis.com/v0/b/confer-2020.appspot.com/o/speakers%2Fgino.jpg?alt=media&token=559d71fa-84fe-4606-926d-f8355c755d6b'
  },
  {
    id: 1,
    name: 'Gloria Ojuku',
    position: 'Software Engineer, Co-lead GDG Cloud PH',
    url: 'https://firebasestorage.googleapis.com/v0/b/confer-2020.appspot.com/o/speakers%2Fgloria.jpeg?alt=media&token=66afca90-2846-45e8-b71a-a5fb41614012'
  },
  {
    id: 2,
    name: 'Steve Anthony',
    position: 'UI/UX Designer, Renaissance Innovation Labs',
    url: 'https://firebasestorage.googleapis.com/v0/b/confer-2020.appspot.com/o/speakers%2Fsteve.jpg?alt=media&token=fceb737e-54aa-4a93-bda8-abed98ca7d11'
  },
  {
    id: 3,
    name: 'Favour Emma',
    position: 'Frontend Developer, Primedsoft',
    url: 'https://firebasestorage.googleapis.com/v0/b/confer-2020.appspot.com/o/speakers%2Ffey.jpg_small?alt=media&token=89fb46f6-5b70-4cb8-8fed-af934a63b04b'
  },
  {
    id: 4,
    name: 'Ray Okaah',
    position: 'Flutter Engineer',
    url: 'https://firebasestorage.googleapis.com/v0/b/confer-2020.appspot.com/o/speakers%2Fray.jpeg?alt=media&token=fdf40b38-caa9-43e3-8184-4ac9372e587b'
  },
  {
    id: 5,
    name: 'Blessing Ogunsekan',
    position: 'Frontend Developer',
    url: 'https://firebasestorage.googleapis.com/v0/b/confer-2020.appspot.com/o/speakers%2Fblessing.jpeg?alt=media&token=887facb2-1658-4ec1-97da-d5b506080dc8'
  },
  {
    id: 6,
    name: 'Godfrey Ayaosi',
    position: 'Co-lead, GDG Port Harcourt',
    url: 'https://firebasestorage.googleapis.com/v0/b/confer-2020.appspot.com/o/speakers%2Fgodfrey.jpg?alt=media&token=8041d3d1-5d6a-4d43-ac78-8d8b62f3acfa'
  },
];