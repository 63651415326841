import React, { Component } from 'react';
import firebase from 'firebase';
import Breadcrumb from '../components/Breadcrumb';
import Spinner from '../components/Spinner';
import '../assets/styles/views/Gallery.css';

function ImageList(props) {
  const images = props.images;
  const listImages = images.map((image, index) =>
    <div className="col-12 col-sm-6 col-lg-4 mt-4" key={index}>
      <img className="img-fluid" src={image.url} alt="gallery"></img>
    </div>
  );
  return <div className="row">{listImages}</div>
}
export default class SpeakerPage extends Component {

  constructor(props) {
    super(props);
    this.handleUpload = this.handleUpload.bind(this);
    this.handleFileInput = this.handleFileInput.bind(this);
    this.fileInput = React.createRef();
    this.state = {
      images: [],
      loading: false,
      user: null,
    };
  }

  componentDidMount() {
    firebase.auth().onAuthStateChanged(currentUser => {
      if (currentUser) {
        this.setState({ user: currentUser });
      }
    });
    this.fetchImages();
  }

  async fetchImages() {
    this.setState({ loading: true, images: [] }, async () => {
      const storage = firebase.storage();
      const storageRef = storage.ref();

      const images = await storageRef.child('gallery').list({ maxResults: 100 });
      images.items.forEach(async (item) => {
        const imagePath = storageRef.child(item.location.path);
        const imgURL = await imagePath.getDownloadURL();
        this.updateImages(imgURL);
      });
    })

  }

  updateImages(url) {
    this.setState({
      images: [...this.state.images, { url }],
      loading: false,
    });
  }

  handleUpload(event) {
    this.setState({ loading: true });
    event.preventDefault();
    const file = this.fileInput.current.files[0];
    if (file.size > 2097152) {
      return alert('File size can\'t exceed 2MB');
    }
    const currentTime = Date.now();
    const storageRef = firebase.storage().ref();
    const uploadImageRef = storageRef.child(`gallery/image-${currentTime}`);
    const uploadTask = uploadImageRef.put(file);

    uploadTask.on('state_changed', (snapshot) => {
      let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      console.log('Upload is ' + progress + '% done');
    });

    this.fetchImages();
  }

  handleFileInput() {
    document.querySelector('#fileUpload').click();
  }

  render() {
    let uploadBtn = null;
    if (this.state.user) {
      uploadBtn = <div className="col-md-12 text-right">
        <input className="file-upload" type="file" ref={this.fileInput} onChange={this.handleUpload} id="fileUpload"></input>
        <button className="btn confer-btn mt-3" onClick={this.handleFileInput}>Upload Image <i className="zmdi zmdi-long-arrow-up"></i></button>
      </div>
    }
    else {
      uploadBtn = <div></div>
    }
    return (
      <div>
        <Breadcrumb name="Gallery" />
        {/* Our Speakings Area Start */}
        <section className="our-speaker-area section-padding-100 gallery">
          <div className="container">

            <div className="row">
              <div className="col-md-12 text-center">
                <Spinner loading={this.state.loading} />
              </div>
              {uploadBtn}
            </div>
            <ImageList images={this.state.images} />
          </div>
        </section>
        {/* Our Speakings Area end */}
      </div>
    )
  }
}