import React from 'react';
import firebase from 'firebase';

export default class Feeds extends React.Component {

  constructor(props) {
    super(props);
    // this.handleNotificationSub = this.handleNotificationSub.bind(this);
    // this.saveMessagingDeviceToken = this.saveMessagingDeviceToken.bind(this);
    // this.state = {
    //   messaging: null,
    // }
  }

  componentDidMount() {
    // if ('serviceWorker' in navigator) {
    //   this.setState({ messaging: firebase.messaging() }, () => {
    //     this.state.messaging.usePublicVapidKey('BLT3wBwiZBSqW4qp3fX4rmQWKNIzo_OMWXV0OvWZWSLnq05nfp_06gMV6xIjp5d94KYFamZbabMSHubYNt-YL6E');

    //     this.state.messaging.onMessage((payload) => {
    //       alert(payload);
    //     });
    //     navigator.serviceWorker.addEventListener("message", ({ data }) =>
    //       console.log(data)
    //     );
    //   });
    // }
  }

  // handleNotificationSub() {
  //   // check if notifications are supported
  //   if ('Notification' in window) {
  //     console.log('Requesting notifications permission...');
  //     this.state.messaging.requestPermission().then(() => {
  //       // Notification permission granted.
  //       this.saveMessagingDeviceToken();
  //     }).catch(function (error) {
  //       console.error('Unable to get permission to notify.', error);
  //     });
  //   }
  // }

  // saveMessagingDeviceToken() {
  //   this.state.messaging.getToken().then((currentToken) => {
  //     if (currentToken) {
  //       console.log('Got FCM device token:', currentToken);
  //       // Saving the Device Token to the datastore.
  //       firebase.database().ref('/fcmTokens').push({ id: currentToken });
  //     } else {
  //       // Need to request permissions to show notifications.
  //       this.handleNotificationSub();
  //     }
  //   }).catch(function (error) {
  //     console.error('Unable to get messaging token.', error);
  //   });
  // }

  render() {
    return (
      <section className="our-blog-area bg-img bg-gradient-overlay section-padding-100-60" style={{
        backgroundImage: `url(img / bg - img / 17.jpg)`
      }}>
        <div className="container">
          <div className="row">
            <div className="col-12">

              <div className="section-heading text-center wow fadeInUp" data-wow-delay="300ms">
                <p>What's Happening</p>
                <h4>Live Feeds</h4>
              </div>
            </div>


            <div className="col-12 col-md-6 col-lg-4">
              <div className="single-blog-area wow fadeInUp" data-wow-delay="300ms">

                <div className="single-blog-text text-center">

                  <p>Street Food Convention will showcase products like Packed food, beef and lamb, street food.</p>
                  <div className="post-meta">
                    <p className="post-author"><i className="zmdi zmdi-account"></i> Laura Green</p>
                  </div>
                </div>
              </div>
            </div>


            <div className="col-12 col-md-6 col-lg-4">
              <div className="single-blog-area wow fadeInUp" data-wow-delay="300ms">

                <div className="single-blog-text text-center">

                  <p>Street Food Convention will showcase products like Packed food, beef and lamb, street food.</p>
                  <div className="post-meta">
                    <p className="post-author"><i className="zmdi zmdi-account"></i>Laura Green</p>
                  </div>
                </div>
              </div>
            </div>


            <div className="col-12 col-md-6 col-lg-4">
              <div className="single-blog-area wow fadeInUp" data-wow-delay="300ms">

                <div className="single-blog-text text-center">

                  <p>Street Food Convention will showcase products like Packed food, beef and lamb, street food.</p>
                  <div className="post-meta">
                    <p className="post-author"><i className="zmdi zmdi-account"></i>Laura Green</p>
                  </div>
                </div>
              </div>
            </div>

          </div>

          {/* <div className="col-12 text-center">

            <button className="btn confer-btn-white" onClick={this.handleNotificationSub}>Subscribe to live feeds</button>
          </div> */}
        </div>
      </section>
    )
  }

}