import React from 'react';
import firebase from 'firebase';
import { NavLink } from 'react-router-dom';
import Spinner from '../Spinner';

export default class FeedForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      fn: firebase.functions(),
      loading: false,
      title: '',
      text: '',
      user: null,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    firebase.auth().onAuthStateChanged(currentUser => {
      if (currentUser) {
        this.setState({ user: currentUser });
      }
    })
  }

  handleChange(e) {
    this.setState({ text: e.target.value });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ loading: true });
    const updateFeeds = this.state.fn.httpsCallable('updateFeeds');
    updateFeeds({
      text: this.state.text,
      timestamp: Date.now(),
    }).then(result => {
      console.log(result);
      this.setState({
        title: '',
        text: '',
        loading: false,
      });
    }).catch(error => this.setState({
      title: '',
      text: '',
      loading: false,
    }));
  }

  render() {
    const user = this.state.user;
    let showForm = null;
    if (user) {
      showForm = <form onSubmit={this.handleSubmit}>
        <div className="form-group">
          <input type="text" className="form-control mb-30" name="name" id="name" placeholder="What's happening..." value={this.state.text} onChange={this.handleChange} required></input>
        </div>
        <button className="btn confer-btn" type="submit">Send</button>
      </form>
    }
    else {
      showForm = <div><NavLink to="/login" style={{ color: '#df42b1' }}>Login</NavLink> to post to feeds</div>
    }

    return (
      <div>
        {showForm}
        <div className="col-12 text-center">
          <Spinner loading={this.state.loading} />
        </div>
      </div>
    )
  }
}