import React from 'react';
import { NavLink } from 'react-router-dom';
import bg1 from '../../assets/images/bg-img/7.jpg';

export default function Schedule(props) {
  return (
    <section className="our-schedule-area section-padding-100">
      <div className="container">
        <div className="row">

          <div className="col-12">
            <div className="section-heading-2 text-center wow fadeInUp" data-wow-delay="300ms">
              <p>Our Timetable</p>
              <h4>Schedule Plan</h4>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="schedule-tab">

              <ul className="nav nav-tabs wow fadeInUp" data-wow-delay="300ms" id="conferScheduleTab" role="tablist">
                <li className="nav-item">
                  <a className="nav-link active" id="monday-tab" data-toggle="tab" href="#step-one" role="tab" aria-controls="step-one" aria-expanded="true">Monday <br></br> <span>January 14, 2019</span></a>
                </li>

                <li className="nav-item">
                  <a className="nav-link" id="tuesday-tab" data-toggle="tab" href="#step-two" role="tab" aria-controls="step-two" aria-expanded="true">Tuesday <br></br> <span>January 15, 2019</span></a>
                </li>

                <li className="nav-item">
                  <a className="nav-link" id="wednesday-tab" data-toggle="tab" href="#step-three" role="tab" aria-controls="step-three" aria-expanded="true">Wednesday <br></br> <span>January 16, 2019</span></a>
                </li>
              </ul>
            </div>


            <div className="tab-content" id="conferScheduleTabContent">
              <div className="tab-pane fade show active" id="step-one" role="tabpanel" aria-labelledby="monday-tab">

                <div className="single-tab-content">
                  <div className="row">
                    <div className="col-12">

                      <div className="single-schedule-area d-flex flex-wrap justify-content-between align-items-center wow fadeInUp" data-wow-delay="300ms">

                        <div className="single-schedule-tumb-info d-flex align-items-center">

                          <div className="single-schedule-tumb">
                            <img src={bg1} alt=""></img>
                          </div>

                          <div className="single-schedule-info">
                            <h6>Dealing with Difficult People</h6>
                            <p>by <span>Gino Osahon</span> / Hub Manager, Ken Saro-Wiwa Innovation Hub</p>
                          </div>
                        </div>

                        <div className="schedule-time-place">
                          <p><i className="zmdi zmdi-time"></i> 12-14 Jan 2020</p>
                          <p><i className="zmdi zmdi-map"></i> The Autograph, Port Harcourt</p>
                        </div>

                        <NavLink to="#" className="btn confer-btn">View More <i className="zmdi zmdi-long-arrow-right"></i></NavLink>
                      </div>


                      <div className="single-schedule-area d-flex flex-wrap justify-content-between align-items-center wow fadeInUp" data-wow-delay="300ms">

                        <div className="single-schedule-tumb-info d-flex align-items-center">

                          <div className="single-schedule-tumb">
                            <img src="https://firebasestorage.googleapis.com/v0/b/confer-2020.appspot.com/o/speakers%2Ffey.jpg_small?alt=media&token=89fb46f6-5b70-4cb8-8fed-af934a63b04b" alt=""></img>
                          </div>

                          <div className="single-schedule-info">
                            <h6>Crop Insurance Conference</h6>
                            <p>by <span>Favour Emma</span> / Frontend Developer, Primedsoft</p>
                          </div>
                        </div>

                        <div className="schedule-time-place">
                          <p><i className="zmdi zmdi-time"></i> 12-14 Jan 2020</p>
                          <p><i className="zmdi zmdi-map"></i> The Autograph, Port Harcourt</p>
                        </div>

                        <NavLink to="#" className="btn confer-btn">View More <i className="zmdi zmdi-long-arrow-right"></i></NavLink>
                      </div>


                      <div className="single-schedule-area d-flex flex-wrap justify-content-between align-items-center wow fadeInUp" data-wow-delay="300ms">

                        <div className="single-schedule-tumb-info d-flex align-items-center">

                          <div className="single-schedule-tumb">
                            <img src="https://firebasestorage.googleapis.com/v0/b/confer-2020.appspot.com/o/speakers%2Fray.jpeg?alt=media&token=fdf40b38-caa9-43e3-8184-4ac9372e587b" alt=""></img>
                          </div>

                          <div className="single-schedule-info">
                            <h6>Capdm Executive Conference</h6>
                            <p>by <span>Ray Okaah</span> / Flutter Engineer</p>
                          </div>
                        </div>

                        <div className="schedule-time-place">
                          <p><i className="zmdi zmdi-time"></i> 12-14 Jan 2020</p>
                          <p><i className="zmdi zmdi-map"></i> The Autograph, Port Harcourt</p>
                        </div>

                        <NavLink to="#" className="btn confer-btn">View More <i className="zmdi zmdi-long-arrow-right"></i></NavLink>
                      </div>

                    </div>


                    <div className="col-12">
                      <div className="more-schedule-btn text-center mt-50 wow fadeInUp" data-wow-delay="300ms">
                        <NavLink to="#" className="btn confer-gb-btn">Dowload schedule (Pdf)</NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="tab-pane fade" id="step-two" role="tabpanel" aria-labelledby="tuesday-tab">

                <div className="single-tab-content">
                  <div className="row">
                    <div className="col-12">

                      <div className="single-schedule-area d-flex flex-wrap justify-content-between align-items-center wow fadeInUp" data-wow-delay="300ms">

                        <div className="single-schedule-tumb-info d-flex align-items-center">

                          <div className="single-schedule-tumb">
                            <img src="https://firebasestorage.googleapis.com/v0/b/confer-2020.appspot.com/o/speakers%2Fgloria.jpeg?alt=media&token=66afca90-2846-45e8-b71a-a5fb41614012" alt=""></img>
                          </div>

                          <div className="single-schedule-info">
                            <h6>Crop Insurance Conference</h6>
                            <p>by <span>Gloria Ojuku</span> / Software Engineer, Co-lead GDG Cloud PH</p>
                          </div>
                        </div>

                        <div className="schedule-time-place">
                          <p><i className="zmdi zmdi-time"></i> 12-14 Jan 2020</p>
                          <p><i className="zmdi zmdi-map"></i> The Autograph, Port Harcourt</p>
                        </div>

                        <NavLink to="#" className="btn confer-btn">View More <i className="zmdi zmdi-long-arrow-right"></i></NavLink>
                      </div>


                      <div className="single-schedule-area d-flex flex-wrap justify-content-between align-items-center wow fadeInUp" data-wow-delay="300ms">

                        <div className="single-schedule-tumb-info d-flex align-items-center">

                          <div className="single-schedule-tumb">
                            <img src="https://firebasestorage.googleapis.com/v0/b/confer-2020.appspot.com/o/speakers%2Fsteve.jpg?alt=media&token=fceb737e-54aa-4a93-bda8-abed98ca7d11" alt=""></img>
                          </div>

                          <div className="single-schedule-info">
                            <h6>Capdm Executive Conference</h6>
                            <p>by <span>Steve Anthony</span> / UI/UX Designer, Renaissance Innovation Labs</p>
                          </div>
                        </div>

                        <div className="schedule-time-place">
                          <p><i className="zmdi zmdi-time"></i> 12-14 Jan 2020</p>
                          <p><i className="zmdi zmdi-map"></i> The Autograph, Port Harcourt</p>
                        </div>

                        <NavLink to="#" className="btn confer-btn">View More <i className="zmdi zmdi-long-arrow-right"></i></NavLink>
                      </div>


                      <div className="single-schedule-area d-flex flex-wrap justify-content-between align-items-center wow fadeInUp" data-wow-delay="300ms">

                        <div className="single-schedule-tumb-info d-flex align-items-center">

                          <div className="single-schedule-tumb">
                            <img src="https://firebasestorage.googleapis.com/v0/b/confer-2020.appspot.com/o/speakers%2Fblessing.jpeg?alt=media&token=887facb2-1658-4ec1-97da-d5b506080dc8" alt=""></img>
                          </div>

                          <div className="single-schedule-info">
                            <h6>Street Food Convention</h6>
                            <p>by <span>Blessing Ogunsekan</span> / Frontend Developer</p>
                          </div>
                        </div>

                        <div className="schedule-time-place">
                          <p><i className="zmdi zmdi-time"></i> 12-14 Jan 2020</p>
                          <p><i className="zmdi zmdi-map"></i> The Autograph, Port Harcourt</p>
                        </div>

                        <NavLink to="#" className="btn confer-btn">View More <i className="zmdi zmdi-long-arrow-right"></i></NavLink>
                      </div>
                    </div>


                    <div className="col-12">
                      <div className="more-schedule-btn text-center mt-50 wow fadeInUp" data-wow-delay="300ms">
                        <NavLink to="#" className="btn confer-gb-btn">Dowload schedule (Pdf)</NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="tab-pane fade" id="step-three" role="tabpanel" aria-labelledby="wednesday-tab">

                <div className="single-tab-content">
                  <div className="row">
                    <div className="col-12">

                      <div className="single-schedule-area d-flex flex-wrap justify-content-between align-items-center wow fadeInUp" data-wow-delay="300ms">

                        <div className="single-schedule-tumb-info d-flex align-items-center">

                          <div className="single-schedule-tumb">
                            <img src="https://firebasestorage.googleapis.com/v0/b/confer-2020.appspot.com/o/speakers%2Fgodfrey.jpg?alt=media&token=8041d3d1-5d6a-4d43-ac78-8d8b62f3acfa" alt=""></img>
                          </div>

                          <div className="single-schedule-info">
                            <h6>Dealing with Difficult People</h6>
                            <p>by <span>Godfrey Ayaosi</span> / Co-lead, GDG Port Harcourt</p>
                          </div>
                        </div>

                        <div className="schedule-time-place">
                          <p><i className="zmdi zmdi-time"></i> 12-14 Jan 2020</p>
                          <p><i className="zmdi zmdi-map"></i> The Autograph, Port Harcourt</p>
                        </div>

                        <NavLink to="#" className="btn confer-btn">View More <i className="zmdi zmdi-long-arrow-right"></i></NavLink>
                      </div>


                      <div className="single-schedule-area d-flex flex-wrap justify-content-between align-items-center wow fadeInUp" data-wow-delay="300ms">

                        <div className="single-schedule-tumb-info d-flex align-items-center">

                          <div className="single-schedule-tumb">
                            <img src={bg1} alt=""></img>
                          </div>

                          <div className="single-schedule-info">
                            <h6>Street Food Convention</h6>
                            <p>by <span>Gino Osahon</span> / Hub Manager, Ken Saro-Wiwa Innovation Hub</p>
                          </div>
                        </div>

                        <div className="schedule-time-place">
                          <p><i className="zmdi zmdi-time"></i> 12-14 Jan 2020</p>
                          <p><i className="zmdi zmdi-map"></i> The Autograph, Port Harcourt</p>
                        </div>

                        <NavLink to="#" className="btn confer-btn">View More <i className="zmdi zmdi-long-arrow-right"></i></NavLink>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="more-schedule-btn text-center mt-50 wow fadeInUp" data-wow-delay="300ms">
                        <NavLink to="#" className="btn confer-gb-btn">View schedule</NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}