import React from 'react';

export default function FeedCard(props) {
  return (
    <div className="single-blog-area" data-wow-delay="300ms">

      <div className="single-blog-text text-center">
        <p className="f-20">{props.text}</p>
        <div className="post-meta">
          <p className="post-author">
            <i className="zmdi zmdi-account"></i> {props.author.name}</p>
        </div>
      </div>

    </div>
  )
}