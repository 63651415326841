import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom';
import firebase from 'firebase';
import { firebaseConfig } from './services/firebase.js';
// import { registerServiceWorker } from "./register-sw";
import './index.css';
import App from './App';

// registerServiceWorker();
firebase.initializeApp(firebaseConfig);

ReactDOM.render(<App />, document.getElementById('root'));
