import React from 'react';
import Welcome from '../components/home/Welcome';
import Speakers from '../components/home/Speakers';
import Schedule from '../components/home/Schedule';
import Contact from '../components/home/Contact';
import Partners from '../components/home/Partners';
import Feeds from '../components/home/Feeds';
import { speakers } from '../data/speakers';
import '../App.css';

function Home() {
  return (
    <div className="App">
      <Welcome />
      <Speakers speakers={speakers} />
      <Schedule />
      <Feeds />
      <Partners />
      <Contact />
    </div>
  );
}

export default Home;