import React from 'react';
import { NavLink } from 'react-router-dom';
import bg from '../assets/images/bg-img/3.jpg';

export default function Breadcrumb(props) {
  return (
    <section className="breadcrumb-area bg-img bg-gradient-overlay jarallax" style={{
      backgroundImage: `url(${bg})`
    }}>
      <div className="container h-100">
        <div className="row h-100 align-items-center">
          <div className="col-12">
            <div className="breadcrumb-content">
              <h2 className="page-title">{props.name}</h2>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><NavLink to="/">Home</NavLink></li>
                  <li className="breadcrumb-item active" aria-current="page">{props.name}</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}